<template>

  <PDialog
    :title="dialogTitle"
    classes="left-0 md:left-auto md:w-4/5 lg:w-3/5"
    @close="$emit('close')"
  >

    <div class="flex-1 p-4">

      <PInput
        v-model="title"
        :disabled="isSaving"
        :label="$tk('Common.General.Title')"
      />

      <PTextarea
        v-model="text"
        :disabled="isSaving"
        :label="$tk('Common.General.Text')"
        class="mt-4"
      />

    </div>

    <PDialogActions>

      <PButton
        :disabled="isSaving"
        color="secondary"
        @click="$emit('close')"
        v-html="$tk('Common.Actions.Close')"
      ></PButton>

      <PButton
        :disabled="!canSave"
        :loading="isSaving"
        class="ml-2"
        @click="onSave"
        v-html="$tk('Common.Actions.Save')"
      ></PButton>

    </PDialogActions>

  </PDialog>

</template>

<script>

import http from "@/utilities/http"

const STORE_SCOPE = "global"
const STORE_TYPE = "application/json"
const STORE_NAME = "support-templates"

export default {

  props: {

    templates: {
      type: Array,
      required: true
    },

    index: {
      type: Number,
      default: -1
    }
  },

  data () {
    return {
      items: [],
      title: "",
      text: "",
      isSaving: false
    }
  },

  computed: {

    canSave () {
      return this.title !== "" && this.text !== ""
    },

    dialogTitle () {
      return this.index >= 0
      ? this.$tk("TemplateDialog.EditTemplate")
      : this.$tk("TemplateDialog.CreateTemplate")
    }

  },

  methods: {

    async onSave () {

      this.isSaving = true

      const value = [...this.templates]

      if (this.index >= 0) {
        value[this.index].id = this.index + 1
        value[this.index].title = this.title
        value[this.index].text = this.text
      } else {
        value.push({
          id: this.index + 1,
          title: this.title,
          text: this.text
        })
      }

      try {

        await http.post("store", {
          scope: STORE_SCOPE,
          type: STORE_TYPE,
          name: STORE_NAME,
          value: JSON.stringify(value)
        })

        this.$emit("close")

      } catch(error) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason
        })
      }

      this.isSaving = false
    },

  },

  created () {
    if (this.index >= 0) {
      this.title = this.templates[this.index].title
      this.text = this.templates[this.index].text
    }
  }

}
</script>

