<template>
  <div
    class="flex flex-col flex-1 overflow-hidden bg-white border border-gray-300 rounded"
  >
    <div class="relative flex-1 overflow-y-scroll">
      <PLoader v-if="isLoading" />
      <table v-else class="data-table">
        <thead>
          <tr>
            <th>
              <div class="p-4 text-left">
                {{ $tk("Common.General.Template") }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(template, index) in templates"
            :key="index"
            :class="{ odd: index % 2 !== 0 }"
            @click="onClick(index)"
          >
            <td class="text-left">
              {{ template.title }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <portal to="actions">
      <PButton color="primary" @click="templateDialogVisible = true">
        <FontAwesomeIcon :icon="['far', 'plus']" class="mr-2" />
        {{ $tk("SupportTemplates.NewTemplate") }}
      </PButton>
    </portal>

    <TemplateDialog
      v-if="templateDialogVisible"
      :templates="templates"
      :index="selectedIndex"
      @close="onClose"
    />
  </div>
</template>

<script>
import TemplateDialog from "../dialogs/TemplateDialog.vue";
import http from "@/utilities/http";
import { get } from "lodash";

const STORE_SCOPE = "global";
const STORE_NAME = "support-templates";

export default {
  components: { TemplateDialog },
  data() {
    return {
      templates: [],
      selectedIndex: -1,
      isLoading: false,
      templateDialogVisible: false,
    };
  },
  methods: {
    onClick(index) {
      this.templateDialogVisible = true;
      this.selectedIndex = index;
    },

    onClose() {
      this.templateDialogVisible = false;
      this.selectedIndex = -1;

      this.init();
    },

    async init() {
      const store = await http.get("Store", {
        params: {
          scope: STORE_SCOPE,
          name: STORE_NAME,
        },
      });
      if (store) {
        this.templates = get(store, "value") || [];
      }
    },
  },

  async created() {
    await this.init();
  },
};
</script>

<style scoped>
th {
  @apply leading-4 bg-gradient-to-b from-gray-500 to-gray-600;
}

tr {
  background-color: theme("colors.gray.50");
}

tr.odd {
  background-color: theme("colors.gray.100");
}

tr:hover {
  background-color: theme("colors.orange.200");
}

td {
  @apply px-4 py-2;
  border-bottom: 1px solid theme("colors.gray.300");
  border-right: 1px solid theme("colors.gray.300");
}

tbody tr {
  cursor: pointer;
}
</style>